import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../hoc/withAuthentication"
import { CustomerDetails as Page } from "../components/Customer/Details/CustomerDetails"

export const query = graphql`
  query {
    page: sanityPageAccountDashboard {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default withAuthentication(({ data, ...props }) => <Page {...props} {...data} />)
