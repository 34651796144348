import React from "react"

import { useApp } from "../../../../hooks/useApp"

export const withCustomerDetailsFormWedding = Component => ({ name = `CustomerDetailsFormWedding`, ...props }) => {
  const {
    config: { settings },
  } = useApp()

  const fields = settings.products.delivery.fields

  Component.displayName = name
  return <Component {...props} fields={fields} />
}
