import React from "react"

import { withCustomerDetailsFormField } from "./withCustomerDetailsFormField"
import { FormLabel, FormInput, FormFluidField } from "../../../Styled/Form"

interface Props {
  customer: any
  field: any
  handleChange: any
}

export const CustomerDetailsFormField = withCustomerDetailsFormField(({ customer, field, handleChange }: Props) => (
  <FormFluidField key={field.name}>
    <FormLabel>{field.label}</FormLabel>
    {field.name === "firstName" ? <FormLabel>Please only use your store name here and no personal names.</FormLabel> : ""}
    {field.name === "lastName" ? <FormLabel>Please put space bar here</FormLabel> : ""}
    <FormInput
      name={field.name}
      value={customer[field.name] || ""}
      onChange={handleChange}
      type={`text`}
      placeholder={field.placeholder}
      readOnly={field.readOnly}
      required={field.required}
    />
  </FormFluidField>
))
