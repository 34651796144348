import React from "react"

import { useLanguage } from "../../../hooks/useLanguage"
import { Account } from "../../Account/Account"

export const withCustomerDetails = Component => ({ name = `CustomerDetails`, ...props }) => {
  const locales = useLanguage(`account`)

  Component.displayName = name
  return (
    <Account {...props} description={locales?.detailsDescription} title={locales?.detailsTitle}>
      <Component {...props} />
    </Account>
  )
}
